<script setup lang="ts">
import moment from 'moment/moment'
import 'moment/locale/fr.js'
import 'moment/locale/en-gb'

const { locale } = useI18n()
moment.locale(locale.value)

const { data: articles } = await useCachedAsyncData('blog', () => {
  try {
    return $fetch('https://blog.planete-croisiere.com/wp-json/wp/v2/posts?per_page=3')
  }
  catch (error) {
    console.error('Failed to fetch articles:', error)
    return []
  }
},
)

if (articles.value) {
  const fetchMediaDetails = async (mediaId) => {
    try {
      const { data: media } = await useCachedAsyncData('blog_media' + mediaId, () => {
        return $fetch(`https://blog.planete-croisiere.com/wp-json/wp/v2/media/${mediaId}`)
      })

      return media.value
    }
    catch (error) {
      console.error('Failed to fetch media details for media ID', mediaId, ':', error)
      return null
    }
  }

  articles.value = await Promise.all(
    articles.value.map(async (article) => {
      if (article.featured_media) {
        article.featured_media_details = await fetchMediaDetails(article.featured_media)
      }
      return article
    }),
  )
}
</script>

<template>
  <div
    v-if="articles && articles.length > 0"
    class="blog-container container"
  >
    <UiElementsWaveTitle>
      <h2>
        {{ $t('blog.title') }}
      </h2>
    </UiElementsWaveTitle>
    <div class="articles-list">
      <div
        v-for="(article, index) in articles"
        :key="index"
        class="article"
      >
        <div
          v-if="article.featured_media_details"
          class="article--visual"
        >
          <a
            :href="article.link"
            title="Article link"
          >
            <NuxtImg
              loading="lazy"
              format="webp"
              sizes="100vw sm:50vw md:600px"
              :src="article?.featured_media_details?.source_url"
              alt="article visual"
            />
          </a>
        </div>
        <div
          class="article--content"
          :class="{ 'article--no-visual': !article?.featured_media_details?.source_url }"
        >
          <div
            class="like-h3"
            v-html="article.title.rendered"
          />
          <p>
            <span>{{ $t('blog.category') }}</span>
            <span>{{ moment(article.date).format('LL') }}</span>
          </p>
          <a
            :href="article.link"
            :title="$t('blog.readMore')"
          >
            {{ $t('blog.readMore') }}
          </a>
        </div>
      </div>
    </div>
    <div class="view-all">
      <UiElementsButton
        :size="'large'"
        target="_blank"
        to="https://blog.planete-croisiere.com/"
      >
        {{ $t('blog.viewAll') }}
      </UiElementsButton>
    </div>
  </div>
</template>

<style lang="scss">
.blog-container {
  padding: $space-2xl $space-md;

  .wave-title {
    h2 {
      font-size: 1.5rem;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        font-size: 2.5rem;
      }
    }
  }

  .articles-list {
    display: flex;
    flex-direction: column;
    gap: $space-lg;
    padding-top: $space-lg;

    @media (min-width: map-get($grid-breakpoints, lg)) {
      flex-direction: row;
      gap: $space-4xl;
    }

    .article {
      position: relative;

      @media (min-width: map-get($grid-breakpoints, lg)) {
        &:first-of-type {
          width: 50%;
          height: 100%;

          .article--visual {
            padding-bottom: 730px;

            img {
              object-position: 45%;
              position: absolute;
            }
          }

          .article--content {
            pointer-events: none;
            background: linear-gradient(0deg, rgba(0,0,0,0.5) 0%, rgba(0,0,0,0) 100%);
            position: absolute;
            bottom: 0;
            color: var(--color-white);
            width: 100%;
            padding: $space-3xl $space-3xl $space-3xl $space-3xl;

            div.like-h3 {
              text-transform: none;
              text-align: left;
              font-size: 2rem;
            }

            p {
              font-size: 0.8rem;
            }

            a {
              color: var(--color-white);
            }
          }
        }

        &:nth-of-type(2), &:nth-of-type(3) {
          width: 25%;
        }

        &:nth-of-type(2) {
          display: flex;
          flex-direction: column;

          .article--visual, .article--no-visual {
            margin-top: auto;
          }
        }
      }

      .article--visual {
        position: relative;
        padding-bottom: 320px;

        @media (min-width: map-get($grid-breakpoints, lg)) {
          padding: 0;
        }

        img {
          display: block;
          width: 100%;
          position: absolute;
          height: 100%;
          object-fit: cover;

          @media (min-width: map-get($grid-breakpoints, lg)) {
            position: unset;
          }
        }
      }

      .article--content {
        display: flex;
        flex-direction: column;
        padding-top: $space-lg;
        gap: $space-sm;

        &.article--no-visual {
          background-color: var(--color-navy-300);
          padding: 2rem;
        }

        div.like-h3 {
          text-transform: none;
          font-size: 1.8rem;
          text-align: start;
          margin: 0;

        }

        p {
          font-weight: 500;
          text-transform: uppercase;
          display: flex;
          gap: $space-md;
          margin: 0;
          margin: 0;

          > span {
            display: inline-block;
          }
        }
      }

      a {
        font-weight: 500;
      }
    }
  }

  .view-all {
    text-align: center;
    padding-top: $space-lg;

    a {
      border: solid 2px;
      background: none;
      color: var(--color-navy-450);
      text-decoration: none;
    }

    @media (min-width: map-get($grid-breakpoints, lg)) {
      padding-top: $space-2xl;
    }
  }
}
</style>
